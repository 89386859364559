/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-floating-promises */

/* eslint-disable react/prop-types */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { CartCtx, useActions } from '@components/contexted';
import { processingPaymentAndOrder, Message } from '@components/ecommerce';
import { Layout } from '@components/layout';
import Cookies from 'js-cookie';
import React, { useEffect } from 'react';

const PurchaseSummary = ({ pageContext, location }) => {
  const { changePaymentStatus, setOrderFromApi, resetCart } = useActions(
    CartCtx,
    ['changePaymentStatus', 'setOrderFromApi', 'resetCart']
  );
  let token = Cookies.get('token');
  if (!token) {
    if (typeof window !== 'undefined') {
      token = localStorage.getItem('token');
    }
  }
  useEffect(() => {
    processingPaymentAndOrder({
      location,
      changePaymentStatus,
      setOrderFromApi,
      resetCart,
      token
    });
  }, []);
  const { page } = pageContext;

  return (
    <Layout {...page}>
      <Message location={location} />
    </Layout>
  );
};
export default PurchaseSummary;
